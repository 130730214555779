import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "documention"
    }}>{`Documention`}</h1>
    <p>{`Here you'll find our documentation for the APIs, modules and some usage guides.`}</p>
    <p>{`If you have any questions or feedback, we'd love to talk with you.`}</p>
    <p>{`Simply contact us via the live-chat or by email at `}<a parentName="p" {...{
        "href": "mailto:hello@nucleus.sh"
      }}>{`hello@nucleus.sh`}</a>{` .`}</p>
    <p>{`See you around!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      